import BikeFlowBasicData from "../routes/BikeFlowBasicData";
import BikeFlowQuote from "../routes/BikeFlowQuote";
import BikeFlowPaymentPlan from "../routes/BikeFlowPaymentPlan";
import BikeFlowAdditionalData from "../routes/BikeFlowAdditionalData";
import BikeFlowImages from "../routes/BikeFlowImages";
import {
  BIKE_FLOW_ROUTE_BASIC_DATA,
  BIKE_FLOW_ROUTE_QUOTE,
  BIKE_FLOW_ROUTE_PAYMENT_PLAN,
  BIKE_FLOW_ROUTE_ADD_DATA,
  BIKE_FLOW_ROUTE_IMAGES,
} from "../routes/routes";

const bikeFlowStepsConfig = [
  {
    label: "1",
    description: "Datos básicos",
    route: BIKE_FLOW_ROUTE_BASIC_DATA,
    element: <BikeFlowBasicData />,
    secured: false,
  },
  {
    label: "2",
    description: "Cotizacion",
    route: BIKE_FLOW_ROUTE_QUOTE,
    element: <BikeFlowQuote />,
    secured: false,
  },
  {
    label: "3",
    description: "Plan de pagos",
    route: BIKE_FLOW_ROUTE_PAYMENT_PLAN,
    element: <BikeFlowPaymentPlan />,
    secured: false,
  },
  {
    label: "4",
    description: "Datos adicionales",
    route: BIKE_FLOW_ROUTE_ADD_DATA,
    element: <BikeFlowAdditionalData />,
    secured: false,
  },
  {
    label: "5",
    description: "Imágenes adjuntas",
    route: BIKE_FLOW_ROUTE_IMAGES,
    element: <BikeFlowImages />,
    secured: true,
  },
];

const getBikeFlowStepConfig = (step) => {
  return 0 < step && step <= bikeFlowStepsConfig.length
    ? bikeFlowStepsConfig[step - 1]
    : null;
};

const getAllBikeFlowStepsConfig = () => {
  return bikeFlowStepsConfig;
};

export { getBikeFlowStepConfig, getAllBikeFlowStepsConfig };
