import Protected from "../components/Login/Protected";
import PolicyDetail from "../components/bikeInsQuote/PolicyDetail";
import PaymentFlowLayout from "../components/common/PaymentFlowLayout";

function PaymentFlowPolicyDetail() {
  return (
    <Protected>
      <PaymentFlowLayout>
        <PolicyDetail />
      </PaymentFlowLayout>
    </Protected>
  );
}

export default PaymentFlowPolicyDetail;
