import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabView, TabPanel } from "primereact/tabview";

import QuoteInfo from "./QuoteInfo";
import useDataCollection from "../../hooks/useDataCollection";
import { API_BIKE_COVERAGES } from "../../utils/apiUrls";
import {
  addQuoteInfo,
  selectBikeFlowSelectedData,
  selectBikeFlowQuoteInfo,
} from "../../reduxToolkit/bikeFlowSlice";
import Spinner from "./Spinner";
import { MUST_QUOTE_PREFIX, QUOTATION_PREFIX } from "../../utils/constants";
import { clientApi } from "../../utils/clientApi";
import {
  selectAuthenticated,
  selectUserProfiles,
} from "../../reduxToolkit/userSlice";

function Quote() {
  const [loadingCoverageTypes, coverageTypes] =
    useDataCollection(API_BIKE_COVERAGES);
  const basicData = useSelector(selectBikeFlowSelectedData);
  const quoteInfo = useSelector(selectBikeFlowQuoteInfo);
  const auth = useSelector(selectAuthenticated);
  const profiles = useSelector(selectUserProfiles);
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [quoteInProgress, setQuoteInProgress] = useState(false);
  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.click();
  }, []);

  useEffect(() => {
    // User is Logged in
    if (auth) {
      setActiveIndex(profiles.privateProfile.isPerson ? 0 : 1);
    }
  }, [auth, profiles]);

  useEffect(() => {
    const getQuote = async (coverageItem) => {
      const response = await clientApi(
        "post",
        `${API_BIKE_COVERAGES}/${coverageItem}`,
        false,
        {},
        {
          bikeCost: basicData.bikeCost,
          bikeInsurancePeriod: basicData.bikeInsurancePeriod.item,
        }
      );

      if (response.ok) {
        const { bikeCost, bikeInsurancePeriod, ...otherData } = response.data;
        const quoteInfo = {
          [MUST_QUOTE_PREFIX + coverageItem]: false,
          [QUOTATION_PREFIX + coverageItem]: otherData,
        };
        dispatch(addQuoteInfo(quoteInfo));
      }
    };

    if (!loadingCoverageTypes && !quoteInProgress) {
      coverageTypes.forEach((coverageType) => {
        if (quoteInfo[MUST_QUOTE_PREFIX + coverageType.item]) {
          getQuote(coverageType.item);
        }
      });
      setQuoteInProgress(true);
    }
  }, [
    basicData,
    coverageTypes,
    dispatch,
    loadingCoverageTypes,
    quoteInfo,
    quoteInProgress,
  ]);

  const tabHeaderPerson = (options) => {
    return (
      <button
        type="button"
        onClick={options.onClick}
        className={options.className}
      >
        <span className="text-sm">
          <i className="pi pi-user mr-2 text-2xl" />
          {options.titleElement}
        </span>
      </button>
    );
  };

  const tabHeaderCompany = (options) => {
    return (
      <button
        type="button"
        onClick={options.onClick}
        className={options.className}
      >
        <span className="text-sm">
          <i className="pi pi-building mr-2 text-2xl" />
          {options.titleElement}
        </span>
      </button>
    );
  };

  const handleTabChange = (event) => {
    setActiveIndex(event.index);
  };

  return (
    <div className="surface-0">
      <a style={{ display: "none" }} href="#top" ref={topRef}>
        Link hidden
      </a>
      <div
        id="top"
        className="text-900 font-bold text-3xl mb-4 text-center text-primary"
      >
        PLANES DE COBERTURA
      </div>
      <div className="text-700 text-xl mb-4 text-center line-height-3">
        Elegí la cobertura que mejor se adapta a tus necesidades
      </div>

      <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
        {(!auth || (auth && profiles.privateProfile.isPerson)) && (
          <TabPanel header="Personas" headerTemplate={tabHeaderPerson}>
            <div className="grid grid-nogutter justify-content-center">
              {loadingCoverageTypes ? (
                <Spinner size="small" />
              ) : (
                <>
                  {coverageTypes
                    .filter((coverageType) => coverageType.planPerson)
                    .map((coverageType) => {
                      return (
                        <QuoteInfo
                          key={coverageType.id}
                          coverageType={coverageType}
                          coveragePlanPerson={true}
                        />
                      );
                    })}
                </>
              )}
            </div>
          </TabPanel>
        )}

        {(!auth || (auth && !profiles.privateProfile.isPerson)) && (
          <TabPanel header="Empresas" headerTemplate={tabHeaderCompany}>
            <div className="grid justify-content-center">
              {loadingCoverageTypes ? (
                <Spinner size="small" />
              ) : (
                <>
                  {coverageTypes
                    .filter((coverageType) => coverageType.planCompany)
                    .map((coverageType) => {
                      return (
                        <QuoteInfo
                          key={coverageType.id}
                          coverageType={coverageType}
                          coveragePlanPerson={false}
                        />
                      );
                    })}
                </>
              )}
            </div>
          </TabPanel>
        )}
      </TabView>
    </div>
  );
}

export default Quote;
