import Protected from "../components/Login/Protected";
import PaymentMethod from "../components/bikeInsQuote/PaymentMethod";
import PaymentFlowLayout from "../components/common/PaymentFlowLayout";

function PaymentFlowMethod() {
  return (
    <Protected>
      <PaymentFlowLayout>
        <PaymentMethod />
      </PaymentFlowLayout>
    </Protected>
  );
}

export default PaymentFlowMethod;
