import { useDispatch } from "react-redux";
import { useRoutes } from "react-router-dom";
import { loginFromTokenThunk } from "../../reduxToolkit/userSlice";
import { useEffect } from "react";

import PageNotFound from "../../routes/PageNotFound";
import { ROUTE_NOT_FOUND } from "../../routes/routes";
import { getAllBikeFlowStepsConfig } from "../../utils/bikeFlowStepsConfig";
import { getAllPaymentFlowStepsConfig } from "../../utils/paymentFlowStepsConfig";

function Main() {
  const dispatch = useDispatch();

  const bikeFlowSteps = getAllBikeFlowStepsConfig();
  const paymentFlowSteps = getAllPaymentFlowStepsConfig();

  // SSO among apps (TiendaBSESOA and TiendaBSEBici for now)
  useEffect(() => {
    dispatch(loginFromTokenThunk());
  }, [dispatch]);

  let routes = [];

  // Bike flow
  bikeFlowSteps.forEach((stepConfig) => {
    const item = { path: stepConfig.route, element: stepConfig.element };
    routes.push(item);
  });

  // Payment flow
  paymentFlowSteps.forEach((stepConfig) => {
    const item = { path: stepConfig.route, element: stepConfig.element };
    routes.push(item);
  });

  // Page not found
  const item = { path: ROUTE_NOT_FOUND, element: <PageNotFound /> };
  routes.push(item);

  // Routes
  const element = useRoutes(routes);

  return <>{element}</>;
}

export default Main;
