import Images from "../components/bikeInsQuote/Images";
import BikeFlowLayout from "../components/common/BikeFlowLayout";
import Protected from "../components/Login/Protected";

function BikeFlowImages() {
  return (
    <Protected>
      <BikeFlowLayout>
        <Images />
      </BikeFlowLayout>
    </Protected>
  );
}

export default BikeFlowImages;
