import { createSlice } from "@reduxjs/toolkit";

import { getAllPaymentFlowStepsConfig } from "../utils/paymentFlowStepsConfig";
import { stepToRoute } from "../utils/stepsHelper";

const initialState = {
  step: 0,
  navigate: false,
  navigateTo: "",
  issueInfo: {},
  invoiceInfo: {},
  selectedData: {},
  comeFromSpe: false,
};

const paymentFlowSlice = createSlice({
  name: "paymentFlow",
  initialState,
  reducers: {
    paymentFlowStepCompleted: (state, action) => {
      const steps = getAllPaymentFlowStepsConfig();
      if (state.step < steps.length) {
        state.step += 1;
        state.navigate = true;
        state.navigateTo = stepToRoute(steps, state.step);
        state.selectedData = { ...state.selectedData, ...action.payload };
      } else {
        state.navigate = false;
        state.navigateTo = "";
      }
    },
    paymentFlowGoToStep: (state, action) => {
      const steps = getAllPaymentFlowStepsConfig();
      if (0 < action.payload && action.payload <= steps.length) {
        state.step = action.payload;
        state.navigate = true;
        state.navigateTo = stepToRoute(steps, state.step);
      } else {
        state.navigate = false;
        state.navigateTo = "";
      }
    },
    paymentFlowInit: (state) => {
      state.step = 0;
      state.navigate = false;
      state.navigateTo = "";
      state.issueInfo = {};
      state.invoiceInfo = {};
      state.selectedData = {};
      state.comeFromSpe = false;
    },
    paymentFlowNavigate: (state, action) => {
      state.navigate = action.payload.navigate;
      state.navigateTo = action.payload.navigate
        ? action.payload.navigateTo
        : "";
    },
    addIssueInfo: (state, action) => {
      state.issueInfo = action.payload;
    },
    addInvoiceInfo: (state, action) => {
      state.invoiceInfo = action.payload;
    },
    setComeFromSpe: (state, action) => {
      state.comeFromSpe = action.payload;
    },
  },
});

const {
  paymentFlowStepCompleted,
  paymentFlowGoToStep,
  paymentFlowInit,
  paymentFlowNavigate,
  addIssueInfo,
  addInvoiceInfo,
  setComeFromSpe,
} = paymentFlowSlice.actions;

// Selectors
const selectPaymentFlowStep = (state) => state.paymentFlow.step;
const selectPaymentFlowNavigate = (state) => {
  return {
    navigate: state.paymentFlow.navigate,
    navigateTo: state.paymentFlow.navigateTo,
  };
};
const selectPaymentFlowIssueInfo = (state) => state.paymentFlow.issueInfo;
const selectPaymentFlowInvoiceInfo = (state) => state.paymentFlow.invoiceInfo;
const selectPaymentFlowSelectedData = (state) => state.paymentFlow.selectedData;
const selectComeFromSpe = (state) => state.paymentFlow.comeFromSpe;

export default paymentFlowSlice.reducer;
export {
  selectPaymentFlowStep,
  selectPaymentFlowNavigate,
  selectPaymentFlowIssueInfo,
  selectPaymentFlowInvoiceInfo,
  selectPaymentFlowSelectedData,
  selectComeFromSpe,
  paymentFlowSlice,
  paymentFlowStepCompleted,
  paymentFlowGoToStep,
  paymentFlowInit,
  paymentFlowNavigate,
  addIssueInfo,
  addInvoiceInfo,
  setComeFromSpe,
};
