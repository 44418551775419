import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import {
  bikeFlowStepCompletedThunk,
  selectBikeFlowSelectedData,
} from "../../reduxToolkit/bikeFlowSlice";
import "./PaymentPlan.css";

function PaymentPlan() {
  const data = useSelector(selectBikeFlowSelectedData);
  const dispatch = useDispatch();
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState(null);
  const [error, setError] = useState(false);
  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.click();
  }, []);

  const firstInstallmentBodyTemplate = (rowData) => {
    return formatCurrency(rowData.firstInstallment);
  };

  const totalBodyTemplate = (rowData) => {
    return formatCurrency(rowData.total);
  };

  const formatCurrency = (value) => {
    return (
      "U$S " +
      value.toLocaleString("es-ES", {
        style: "decimal",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    );
  };

  const onRowSelect = (event) => {
    setError(false);
  };

  const handleClick = () => {
    if (selectedPaymentPlan) {
      dispatch(
        bikeFlowStepCompletedThunk({ paymentPlan: selectedPaymentPlan })
      );
    } else {
      setError(true);
    }
  };

  return (
    <>
      <a style={{ display: "none" }} href="#top" ref={topRef}>
        Link hidden
      </a>
      <div
        id="top"
        className="text-900 font-bold text-3xl mb-4 text-center text-primary"
      >
        PLANES DE PAGO
      </div>
      <div className="text-700 text-xl mb-4 text-center line-height-3">
        ¿En cuántas cuotas querés pagar?
      </div>

      <DataTable
        id="paymentPlanDataTable"
        value={data.insurance.paymentPlan}
        selectionMode="single"
        selection={selectedPaymentPlan}
        onSelectionChange={(e) => setSelectedPaymentPlan(e.value)}
        dataKey="id"
        responsiveLayout="stack"
        onRowSelect={onRowSelect}
        stripedRows
      >
        <Column
          header="Plan"
          field="label"
          className="bg-primary border-round-left w-4"
        />
        <Column
          header="Primer cuota"
          field="firstInstallment"
          className="bg-primary w-4"
          body={firstInstallmentBodyTemplate}
          sortable
        ></Column>

        <Column
          header="Total"
          field="total"
          className="bg-primary border-round-right w-4"
          body={totalBodyTemplate}
          sortable
        />
      </DataTable>

      <div className="text-center mt-4">
        {error && (
          <Message
            severity="error"
            text="Debe seleccionar un plan de pago."
            className="w-full mb-2"
          />
        )}
        <Button label="Contratar" className="w-8" onClick={handleClick} />
      </div>
    </>
  );
}

export default PaymentPlan;
