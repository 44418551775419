import BikeFlowHeader from "./BikeFlowHeader";
import BikeFlowFooter from "./BikeFlowFooter";
import DebtMessage from "./DebtMessage";

function BikeFlowLayoutDebt({ handleClick }) {
  return (
    <>
      <BikeFlowHeader />
      <DebtMessage handleClick={handleClick} isBikeFlow={true} />
      <BikeFlowFooter />
    </>
  );
}

export default BikeFlowLayoutDebt;
