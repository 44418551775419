import { useDispatch, useSelector } from "react-redux";

import {
  selectAuthenticated,
  selectUserProfiles,
} from "../../reduxToolkit/userSlice";
import Login from "./Login";
import useAppFlowController from "../../hooks/useAppFlowController";
import { selectPaymentFlowStep } from "../../reduxToolkit/paymentFlowSlice";
import BikeFlowLayoutDebt from "../common/BikeFlowLayoutDebt";
import PaymentFlowLayoutDebt from "../common/PaymentFlowLayoutDebt";
import { bikeFlowGoToFirstStep } from "../../reduxToolkit/bikeFlowSlice";

function Protected({ children }) {
  const dispatch = useDispatch();

  const auth = useSelector(selectAuthenticated);
  const profiles = useSelector(selectUserProfiles);
  const paymentFlowStep = useSelector(selectPaymentFlowStep);

  const accessGranted = useAppFlowController();

  const handleClick = () => {
    dispatch(bikeFlowGoToFirstStep());
  };

  return (
    <>
      {accessGranted && (
        <>
          {!auth ? (
            <Login />
          ) : profiles.privateProfile.debt ? (
            paymentFlowStep <= 0 ? (
              <BikeFlowLayoutDebt handleClick={handleClick} />
            ) : (
              <PaymentFlowLayoutDebt handleClick={handleClick} />
            )
          ) : (
            <> {children}</>
          )}
        </>
      )}
    </>
  );
}

export default Protected;
