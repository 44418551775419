import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Message } from "primereact/message";
import { Checkbox } from "primereact/checkbox";
import { BlockUI } from "primereact/blockui";

import Summary from "./Summary";
import { DUMMY_LINK } from "../../utils/constants";
import Spinner from "./Spinner";
import {
  selectPaymentFlowSelectedData,
  paymentFlowStepCompleted,
  selectComeFromSpe,
} from "../../reduxToolkit/paymentFlowSlice";
import "./PolicyDetail.css";
import FormSpe from "./FormSpe";

function PolicyDetail() {
  // Constants
  const TERM_ONE_ID = "termOne";
  const TERM_TWO_ID = "termTwo";
  const TERM_THREE_ID = "termThree";
  const TIME_OUT = 5000; // Just to display a text message (Procesando...) for a while.

  const dispatch = useDispatch();

  const selectedData = useSelector(selectPaymentFlowSelectedData);
  const comeFromSpe = useSelector(selectComeFromSpe);
  const [postToSpe, setPostToSpe] = useState(false);
  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.click();
  }, []);

  useEffect(() => {
    let timer;
    if (comeFromSpe) {
      timer = setTimeout(() => {
        dispatch(paymentFlowStepCompleted()); // No need to add more data to the store.
      }, TIME_OUT);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const defaultValues = JSON.parse(
    `{"${TERM_ONE_ID}":"", "${TERM_TWO_ID}":"", "${TERM_THREE_ID}":""}`
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  const onSubmit = () => {
    if (selectedData.paymentMethod.paymentGateway) {
      topRef.current.click();
      setPostToSpe(true);
    } else {
      dispatch(paymentFlowStepCompleted()); // No need to add more data to the store.
    }
  };

  const getFormErrorMessage = (names) => {
    const errorKeys = Object.keys(errors);
    const error = names.findIndex((name) => errorKeys.includes(name));
    return error >= 0 ? (
      <Message
        severity="error"
        text="Para continuar debe aceptar los términos y condiciones."
      />
    ) : (
      ""
    );
  };

  return (
    <>
      <a style={{ display: "none" }} href="#top" ref={topRef}>
        Link hidden
      </a>
      <div id="top" className="md:w-11 m-auto">
        {comeFromSpe || postToSpe ? (
          <>
            <div className="flex flex-column justify-content-center align-items-center h-15rem">
              <span className="text-900 font-bold text-3xl text-blue-500">
                PROCESANDO
              </span>
              <span className="text-700 text-xl text-center line-height-3">
                Por favor espera unos segundos...
              </span>
            </div>

            <FormSpe post={postToSpe} timeOut={TIME_OUT} />

            <BlockUI
              blocked={true}
              fullScreen
              template={<Spinner size="big" />}
            />
          </>
        ) : (
          <>
            <div className="text-900 font-bold text-3xl mb-4 text-center text-blue-500 mt-5">
              RESUMEN DE COMPRA
            </div>

            <Summary />

            <div className="form-data">
              <div className="flex justify-content-center">
                <div className="card">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="p-fluid mt-5"
                  >
                    <div className="mb-5 text-justify">
                      <Controller
                        name={TERM_ONE_ID}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field, fieldState }) => (
                          <Checkbox
                            inputId={field.name}
                            checked={field.value}
                            onChange={(e) => field.onChange(e.checked)}
                            className={`policyDetailCheckbox mr-2 ${classNames({
                              "p-invalid": fieldState.invalid,
                            })}`}
                            inputRef={field.ref}
                          />
                        )}
                      />
                      <label
                        htmlFor={TERM_ONE_ID}
                        className={classNames({
                          "p-error": errors[TERM_ONE_ID],
                        })}
                      >
                        Acepto los Términos y condiciones de la póliza que se
                        presentan
                        <a
                          href={DUMMY_LINK}
                          target="_blank"
                          rel="noreferrer"
                          className="font-medium no-underline mx-1 text-blue-500 hover:text-blue-300 cursor-pointer"
                        >
                          aquí.
                        </a>
                      </label>
                    </div>

                    <div className="mb-5 text-justify">
                      <Controller
                        name={TERM_TWO_ID}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field, fieldState }) => (
                          <Checkbox
                            inputId={field.name}
                            checked={field.value}
                            onChange={(e) => field.onChange(e.checked)}
                            className={`policyDetailCheckbox mr-2 ${classNames({
                              "p-invalid": fieldState.invalid,
                            })}`}
                            inputRef={field.ref}
                          />
                        )}
                      />
                      <label
                        htmlFor={TERM_TWO_ID}
                        className={classNames({
                          "p-error": errors[TERM_TWO_ID],
                        })}
                      >
                        Para que el seguro entre en vigencia y el usuario pueda
                        hacer uso de la cobertura, se deberá efectuar un pago en
                        línea al momento de la contratación. Si el plan de pago
                        es contado se deberá abonar la totalidad del costo del
                        seguro; si el plan es financiado se deberá abonar la
                        primer cuota.
                      </label>
                    </div>

                    <div className="mb-5 text-justify">
                      <Controller
                        name={TERM_THREE_ID}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field, fieldState }) => (
                          <Checkbox
                            inputId={field.name}
                            checked={field.value}
                            onChange={(e) => field.onChange(e.checked)}
                            className={`policyDetailCheckbox mr-2 ${classNames({
                              "p-invalid": fieldState.invalid,
                            })}`}
                            inputRef={field.ref}
                          />
                        )}
                      />
                      <label
                        htmlFor={TERM_THREE_ID}
                        className={classNames({
                          "p-error": errors[TERM_THREE_ID],
                        })}
                      >
                        El seguro entrará en vigencia una vez que se efectúe el
                        pago en redes, dentro del plazo estipulado desde su
                        contratación. Dicho plazo, establece la validez de la
                        factura y es de 48 horas.
                      </label>
                    </div>

                    {getFormErrorMessage([
                      TERM_ONE_ID,
                      TERM_TWO_ID,
                      TERM_THREE_ID,
                    ])}
                    <Button
                      type="submit"
                      label="Pagar"
                      className="p-button-info mt-2"
                    />
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default PolicyDetail;
