const BIKE_FLOW_ROUTE_BASIC_DATA = "/datosBasicos";
const BIKE_FLOW_ROUTE_QUOTE = "/cotizacion";
const BIKE_FLOW_ROUTE_PAYMENT_PLAN = "/planDePagos";
const BIKE_FLOW_ROUTE_ADD_DATA = "/datosAdicionales";
const BIKE_FLOW_ROUTE_IMAGES = "/imagenes";

const PAYMENT_FLOW_ROUTE_METHOD = "/metodoDePago";
const PAYMENT_FLOW_ROUTE_POLICY_DETAIL = "/detalleDePoliza";
const PAYMENT_FLOW_ROUTE_INSURANCE = "/seguroContratado";

const ROUTE_NOT_FOUND = "*";

export {
  BIKE_FLOW_ROUTE_BASIC_DATA,
  BIKE_FLOW_ROUTE_QUOTE,
  BIKE_FLOW_ROUTE_PAYMENT_PLAN,
  BIKE_FLOW_ROUTE_ADD_DATA,
  BIKE_FLOW_ROUTE_IMAGES,
  PAYMENT_FLOW_ROUTE_METHOD,
  PAYMENT_FLOW_ROUTE_POLICY_DETAIL,
  PAYMENT_FLOW_ROUTE_INSURANCE,
  ROUTE_NOT_FOUND,
};
