import PaymentFlowHeader from "./PaymentFlowHeader";
import PaymentFlowFooter from "./PaymentFlowFooter";
import DebtMessage from "./DebtMessage";

function PaymentFlowLayoutDebt({ handleClick }) {
  return (
    <>
      <PaymentFlowHeader />
      <DebtMessage handleClick={handleClick} isBikeFlow={false} />
      <PaymentFlowFooter />
    </>
  );
}

export default PaymentFlowLayoutDebt;
