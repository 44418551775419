import { useSelector } from "react-redux";
import { selectBikeFlowSelectedData } from "../../reduxToolkit/bikeFlowSlice";
import { selectPaymentFlowSelectedData } from "../../reduxToolkit/paymentFlowSlice";
import { selectUserProfiles } from "../../reduxToolkit/userSlice";

function Summary() {
  const bikeFlowSelectedData = useSelector(selectBikeFlowSelectedData);
  const userProfiles = useSelector(selectUserProfiles);
  const paymentFlowSelectedData = useSelector(selectPaymentFlowSelectedData);

  return (
    <div className="grid grid-nogutter border-top-1 border-bottom-1 border-blue-500 surface-border py-2">
      <div className="col-12 md:col-4 p-3">
        <div className="text-blue-500 text-xl mb-2">Cobertura</div>
        <div className="text-700 text-xl">
          {bikeFlowSelectedData.insurance.coverageLabel}
        </div>
      </div>

      <div className="col-12 md:col-4 p-3">
        <div className="text-blue-500 text-xl mb-2">N° de cotización</div>
        <div className="text-700 text-xl">
          {bikeFlowSelectedData.insurance.quotation}
        </div>
      </div>

      <div className="col-12 md:col-4 p-3">
        <div className="text-blue-500 text-xl mb-2">Nombre del contratante</div>
        <div className="text-700 text-xl">
          {userProfiles.publicProfile.fullName}
        </div>
      </div>

      <div className="col-12 md:col-4 p-3">
        <div className="text-blue-500 text-xl  mb-2">N° de factura</div>
        <div className="text-700 text-xl">
          {paymentFlowSelectedData.invoice}
        </div>
      </div>

      <div className="col-12 md:col-4 p-3">
        <div className="text-blue-500 text-xl  mb-2">Importe</div>
        <div className="text-700 text-xl">
          {`${
            bikeFlowSelectedData.insurance.currencySymbol
          } ${bikeFlowSelectedData.insurance.premium.toLocaleString("es-ES", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}`}
        </div>
      </div>

      <div className="col-12 md:col-4 p-3">
        <div className="text-blue-500 text-xl  mb-2">Medio de pago</div>
        <div className="text-700 text-xl">
          <span className="mr-1">
            {paymentFlowSelectedData.paymentMethod.label}
          </span>
          <span>{paymentFlowSelectedData.bank?.label}</span>
          <span>{paymentFlowSelectedData.creditCard?.label}</span>
        </div>
      </div>

      <div className="col-12 p-3">
        <div className="text-blue-500 text-xl  mb-3">Imágenes recibidas</div>

        {bikeFlowSelectedData.images.map((file, index) => {
          return (
            <div
              key={index}
              className={`flex flex-column md:flex-row md:align-items-center md:justify-content-between ${
                index === 0 ? "border-top-1" : ""
              } border-bottom-1 surface-border p-1`}
            >
              <div className="flex align-items-center">
                <span className="block pi pi-file mr-2"></span>
                <span className="text-700">{file}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Summary;
