import Protected from "../components/Login/Protected";
import Insurance from "../components/bikeInsQuote/Insurance";
import PaymentFlowLayout from "../components/common/PaymentFlowLayout";

function PaymentFlowInsurance() {
  return (
    <Protected>
      <PaymentFlowLayout>
        <Insurance />
      </PaymentFlowLayout>
    </Protected>
  );
}

export default PaymentFlowInsurance;
