import PaymentPlan from "../components/bikeInsQuote/PaymentPlan";
import BikeFlowLayout from "../components/common/BikeFlowLayout";

function BikeFlowPaymentPlan() {
  return (
    <BikeFlowLayout>
      <PaymentPlan />
    </BikeFlowLayout>
  );
}

export default BikeFlowPaymentPlan;
