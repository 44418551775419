import logoBSE from "../../images/bse-logo-negative.png";
import Footer from "./Footer";

function PaymentFlowFooter() {
  return (
    <Footer
      bgColor="bg-indigo-900"
      logo={logoBSE}
      heightLogo="h-3rem"
      hoverTextColor="text-blue-500"
    />
  );
}

export default PaymentFlowFooter;
