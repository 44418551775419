import { createSlice } from "@reduxjs/toolkit";

import { BIKE_FLOW_ROUTE_BASIC_DATA } from "../routes/routes";
import { getAllBikeFlowStepsConfig } from "../utils/bikeFlowStepsConfig";
import {
  getFirstRoute,
  getFirstStep,
  getLastStep,
  stepToRoute,
} from "../utils/stepsHelper";
import { paymentFlowStepCompleted } from "./paymentFlowSlice";

const initialState = {
  step: 1,
  navigate: true,
  navigateTo: BIKE_FLOW_ROUTE_BASIC_DATA,
  quoteInfo: {},
  selectedData: {},
};

const bikeFlowSlice = createSlice({
  name: "bikeFlow",
  initialState,
  reducers: {
    bikeFlowStepCompleted: (state, action) => {
      const steps = getAllBikeFlowStepsConfig();
      if (state.step < steps.length) {
        state.step += 1;
        state.navigate = true;
        state.navigateTo = stepToRoute(steps, state.step);
        state.selectedData = { ...state.selectedData, ...action.payload };
      } else {
        state.navigate = false;
        state.navigateTo = "";
      }
    },
    bikeFlowGoToStep: (state, action) => {
      const steps = getAllBikeFlowStepsConfig();
      if (0 < action.payload && action.payload <= steps.length) {
        state.step = action.payload;
        state.navigate = true;
        state.navigateTo = stepToRoute(steps, state.step);
      } else {
        state.navigate = false;
        state.navigateTo = "";
      }
    },
    bikeFlowGoToFirstStep: (state) => {
      state.step = getFirstStep();
      state.navigate = true;
      state.navigateTo = getFirstRoute(getAllBikeFlowStepsConfig());
    },
    bikeFlowInit: (state) => {
      state.step = 0;
      state.navigate = false;
      state.navigateTo = "";
      state.quoteInfo = {};
      state.selectedData = {};
    },
    bikeFlowNavigate: (state, action) => {
      state.navigate = action.payload.navigate;
      state.navigateTo = action.payload.navigate
        ? action.payload.navigateTo
        : "";
    },
    addQuoteInfo: (state, action) => {
      state.quoteInfo = { ...state.quoteInfo, ...action.payload };
    },
    addSelectedData: (state, action) => {
      state.selectedData = { ...state.selectedData, ...action.payload };
    },
  },
});

const {
  bikeFlowStepCompleted,
  bikeFlowGoToStep,
  bikeFlowGoToFirstStep,
  bikeFlowInit,
  bikeFlowNavigate,
  addQuoteInfo,
  addSelectedData,
} = bikeFlowSlice.actions;

const bikeFlowStepCompletedThunk = (data) => (dispatch, getState) => {
  const { bikeFlow } = getState();

  if (bikeFlow.step === getLastStep(getAllBikeFlowStepsConfig())) {
    dispatch(addSelectedData(data));
    dispatch(paymentFlowStepCompleted());
  } else {
    dispatch(bikeFlowStepCompleted(data));
  }
};

// Selectors
const selectBikeFlowStep = (state) => state.bikeFlow.step;
const selectBikeFlowNavigate = (state) => {
  return {
    navigate: state.bikeFlow.navigate,
    navigateTo: state.bikeFlow.navigateTo,
  };
};
const selectBikeFlowQuoteInfo = (state) => state.bikeFlow.quoteInfo;
const selectBikeFlowSelectedData = (state) => state.bikeFlow.selectedData;

export default bikeFlowSlice.reducer;
export {
  selectBikeFlowStep,
  selectBikeFlowNavigate,
  selectBikeFlowQuoteInfo,
  selectBikeFlowSelectedData,
  bikeFlowSlice,
  bikeFlowStepCompletedThunk,
  bikeFlowGoToStep,
  bikeFlowGoToFirstStep,
  bikeFlowInit,
  bikeFlowNavigate,
  addQuoteInfo,
};
