const API_SESSIONS = "sessions";
const API_PRIVATE_PROFILE = "userPrivateProfile";
const API_SITE_VERIFY = "siteVerify";
const API_USERS = "users";
const API_BIKE_INSURANCE_PERIODS = "bikeInsurancePeriods";
const API_BIKE_COVERAGES = "bikeCoverages";
const API_BIKE_TYPES = "bikeTypes";
const API_FILE_UPLOAD = "uploadFile";
const API_DOCUMENT_TYPES = "documentTypes";
const API_PAYMENT_METHODS = "paymentMethods";
const API_BANKS = "banks";
const API_CREDIT_CARDS = "creditCards";
const API_ISSUE = "issueBike";
const API_INVOICE = "invoice";
const API_SISTARBANC_BACK_URL = "sistarbanc";

export {
  API_SESSIONS,
  API_PRIVATE_PROFILE,
  API_SITE_VERIFY,
  API_USERS,
  API_BIKE_INSURANCE_PERIODS,
  API_BIKE_COVERAGES,
  API_BIKE_TYPES,
  API_FILE_UPLOAD,
  API_DOCUMENT_TYPES,
  API_PAYMENT_METHODS,
  API_BANKS,
  API_CREDIT_CARDS,
  API_ISSUE,
  API_INVOICE,
  API_SISTARBANC_BACK_URL,
};
