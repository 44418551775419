import logoBikeBSE from "../../images/bike-bse-logo.png";
import Footer from "./Footer";

function BikeFlowFooter() {
  return (
    <Footer
      bgColor="bg-cyan-900"
      logo={logoBikeBSE}
      heightLogo="h-7rem"
      hoverTextColor="text-primary"
    />
  );
}

export default BikeFlowFooter;
