import { useSelector, useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { useEffect, useRef } from "react";

import {
  selectPaymentFlowSelectedData,
  setComeFromSpe,
} from "../../reduxToolkit/paymentFlowSlice";
import { selectUserProfiles } from "../../reduxToolkit/userSlice";
import { bikeFlowGoToFirstStep } from "../../reduxToolkit/bikeFlowSlice";

function Insurance() {
  const dispatch = useDispatch();
  const selectedData = useSelector(selectPaymentFlowSelectedData);
  const userProfiles = useSelector(selectUserProfiles);
  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.click();
  }, []);

  useEffect(() => {
    dispatch(setComeFromSpe(false));
  }, [dispatch]);

  const handleClick = () => {
    dispatch(bikeFlowGoToFirstStep());
  };

  return (
    <>
      <a style={{ display: "none" }} href="#top" ref={topRef}>
        Link hidden
      </a>
      <div
        id="top"
        className="grid grid-nogutter border-top-1 border-bottom-1 border-blue-500 surface-border pt-2 text-center"
      >
        <div className="col-12 md:col-4 p-3">
          <div className="text-blue-500 text-xl mb-2">N° póliza emitida</div>
          <div className="text-700 text-xl">{selectedData.policy}</div>
        </div>
        <div className="col-12 md:col-4 p-3">
          <div className="text-blue-500 text-xl mb-2">Transacción</div>
          <div className="text-700 text-xl">
            {selectedData.trn.substr(0, selectedData.trn.length / 2)}
          </div>
          <div className="text-700 text-xl">
            {selectedData.trn.substr(selectedData.trn.length / 2)}
          </div>
        </div>
        <div className="col-12 md:col-4 p-3">
          <div className="text-blue-500 text-xl mb-2">email</div>
          <div className="text-700 text-xl">
            {userProfiles.publicProfile.email}
          </div>
        </div>
      </div>

      <div className="surface-section mt-3 md:mt-0 p-3 md:p-6">
        <div className="flex align-items-start p-2 md:p-4 bg-blue-100 border-round border-1 border-blue-300">
          <i className="hidden md:block pi pi-check-circle text-blue-900 text-2xl md:text-3xl mr-3" />
          <div className="md:mr-3">
            <div className="text-center md:text-left text-blue-900 font-medium text-2xl line-height-1 mb-3">
              <i className="md:hidden text-2xl pi pi-check-circle text-blue-900 mr-2" />
              Transacción confirmada con éxito
            </div>
            <div className="m-0 p-0 text-blue-700 text-center md:text-justify md:text-xl">
              Recibirás la documentación correspondiente en tu correo
              electrónico {userProfiles.publicProfile.email}.
            </div>
          </div>
          <div className="hidden md:block ml-auto">
            <span
              className="inline-flex align-items-center justify-content-center ml-auto border-circle hover:bg-blue-50 no-underline cursor-pointer transition-colors transition-duration-150"
              style={{ width: "1.5rem", height: "1.5rem" }}
              onClick={handleClick}
            >
              <i className="pi pi-times text-blue-900"></i>
            </span>
          </div>
        </div>
        <Button
          label="Volver a cotizar"
          className="p-3 w-full p-button-outlined mt-4 p-button-info"
          onClick={handleClick}
        />
      </div>
    </>
  );
}

export default Insurance;
