import BasicData from "../components/bikeInsQuote/BasicData";
import BikeFlowLayout from "../components/common/BikeFlowLayout";

function BikeFlowBasicData() {
  return (
    <BikeFlowLayout>
      <BasicData />
    </BikeFlowLayout>
  );
}

export default BikeFlowBasicData;
