import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Message } from "primereact/message";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { BlockUI } from "primereact/blockui";
import { Messages } from "primereact/messages";

import {
  API_PAYMENT_METHODS,
  API_BANKS,
  API_CREDIT_CARDS,
  API_ISSUE,
  API_INVOICE,
} from "../../utils/apiUrls";
import Spinner from "./Spinner";
import useDataCollection from "../../hooks/useDataCollection";
import useFocusController from "../../hooks/useFocusController";
import "./PaymentMethod.css";
import Detail from "./Detail";
import {
  addInvoiceInfo,
  addIssueInfo,
  paymentFlowStepCompleted,
  selectPaymentFlowInvoiceInfo,
  selectPaymentFlowIssueInfo,
  setComeFromSpe,
} from "../../reduxToolkit/paymentFlowSlice";
import { clientApi } from "../../utils/clientApi";
import { selectBikeFlowSelectedData } from "../../reduxToolkit/bikeFlowSlice";
import { selectUserId } from "../../reduxToolkit/userSlice";

function PaymentMethod() {
  const PAYMENT_METHOD_ID_FAKE = "paymentMethodFake";
  const PAYMENT_METHOD_ID_REAL = "paymentMethodReal";
  const BANK_ID = "bank";
  const CREDIT_CARD_ID = "creditCard";

  const dispatch = useDispatch();
  const [loadingPaymentMethods, paymentMethods] = useDataCollection(
    API_PAYMENT_METHODS,
    true
  );
  const [loadingBanks, banks] = useDataCollection(API_BANKS, true);
  const [loadingCreditCards, creditCards] = useDataCollection(
    API_CREDIT_CARDS,
    true
  );
  const selectedData = useSelector(selectBikeFlowSelectedData);
  const userId = useSelector(selectUserId);
  const issueInfo = useSelector(selectPaymentFlowIssueInfo);
  const invoiceInfo = useSelector(selectPaymentFlowInvoiceInfo);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [submittedData, setSubmittedData] = useState(null);
  const [blockedDocument, setBlockedDocument] = useState(false);
  const topRef = useRef(null);
  const msgs = useRef(null);

  useEffect(() => {
    topRef.current.click();
  }, []);

  useEffect(() => {
    if (submittedData) {
      dispatch(setComeFromSpe(false));
      dispatch(paymentFlowStepCompleted(submittedData));
    }
  }, [submittedData, dispatch]);

  // Ids and focus functions of all elements on the screen in order.
  const elements = [
    {
      id: PAYMENT_METHOD_ID_FAKE,
      focus: () => topRef.current.click(),
    },
    {
      id: BANK_ID,
      focus: () => setFocus(BANK_ID),
    },
    {
      id: CREDIT_CARD_ID,
      focus: () => setFocus(CREDIT_CARD_ID),
    },
  ];

  const defaultValues = JSON.parse(
    `{"${PAYMENT_METHOD_ID_FAKE}":"", "${BANK_ID}":"", "${CREDIT_CARD_ID}":""}`
  );

  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    resetField,
    setFocus,
  } = useForm({ defaultValues });

  const errorKeys = Object.keys(errors);

  useFocusController(elements, errorKeys);

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <Message severity="error" text={errors[name].message} />
    );
  };

  const handleChange = (event) => {
    setSelectedPaymentMethod(event.value);
    resetField(BANK_ID);
    resetField(CREDIT_CARD_ID);
    setValue(PAYMENT_METHOD_ID_FAKE, event.value, {
      shouldValidate: true,
    });
  };

  const issue = async (
    documentType,
    documentId,
    paymentPlan,
    bikeType,
    brand,
    serial,
    invoiceDate,
    quotation
  ) => {
    let response = null;
    if (issueInfo.mustIssue) {
      const responseIssue = await clientApi(
        "post",
        API_ISSUE,
        true,
        {},
        {
          documentType,
          documentId,
          paymentPlan,
          bikeType,
          brand,
          serial,
          invoiceDate,
          quotation,
        }
      );
      if (responseIssue.ok) {
        const { productId, productLabel, branchOffice, branch, policy } =
          responseIssue.data;
        response = { productId, productLabel, branchOffice, branch, policy };
        dispatch(addIssueInfo({ mustIssue: false, issue: response }));
      } else {
        console.error("*** ISSUE ERROR", responseIssue.data);
      }
    } else {
      response = issueInfo.issue;
    }
    return response;
  };

  const invoice = async (branch, policy) => {
    let response = null;
    if (invoiceInfo.mustInvoice) {
      const responseInvoice = await clientApi(
        "post",
        API_INVOICE,
        true,
        {},
        {
          branch,
          policy,
        }
      );
      if (responseInvoice.ok) {
        response = responseInvoice.data;
        dispatch(addInvoiceInfo({ mustInvoice: false, invoice: response }));
      } else {
        console.error("*** INVOICE ERROR", responseInvoice.data);
      }
    } else {
      response = invoiceInfo.invoice;
    }
    return response;
  };

  const onSubmit = async (data) => {
    setBlockedDocument(true);
    const responseIssue = await issue(
      userId.documentType,
      userId.documentId,
      selectedData.paymentPlan.item,
      selectedData.bikeType.item,
      selectedData.brand,
      selectedData.serial,
      selectedData.invoiceDate,
      selectedData.insurance.quotation
    );
    if (responseIssue) {
      const responseInvoice = await invoice(
        responseIssue.branch,
        responseIssue.policy
      );
      if (responseInvoice) {
        const { paymentMethodFake, ...otherData } = data;
        setSubmittedData({
          paymentMethod: paymentMethodFake,
          ...otherData,
          ...responseIssue,
          ...responseInvoice,
        });
        setSelectedPaymentMethod("");
      } else {
        msgs.current.replace({
          severity: "error",
          detail: (
            <span>
              <strong>Error al facturar la póliza</strong>
              <br />
              Este error fue
              <strong className="mx-1">generado aleatoriamente</strong>por los
              microservicios del API RESTful que esta aplicación consume.
              Presione continuar para reintentar la operación.
            </span>
          ),
          sticky: true,
        });
      }
    } else {
      msgs.current.replace({
        severity: "error",
        detail: (
          <span>
            <strong>Error al emitir la póliza</strong>
            <br />
            Este error fue
            <strong className="mx-1">generado aleatoriamente</strong>por los
            microservicios del API RESTful que esta aplicación consume. Presione
            continuar para reintentar la operación.
          </span>
        ),
        sticky: true,
      });
    }
    setBlockedDocument(false);
  };

  return (
    <>
      <div className="mt-3">
        <Detail />
      </div>

      <a style={{ display: "none" }} href="#top" ref={topRef}>
        Link hidden
      </a>
      <div
        id="top"
        className="text-900 font-bold text-3xl mb-4 text-center text-blue-500 mt-3"
      >
        MÉTODOS DE PAGO
      </div>
      <div className="text-700 text-xl mb-0 md:mb-4 text-center line-height-3">
        Selecciona el método de pago a utilizar
      </div>

      <Messages ref={msgs} />

      <div className="form-data">
        <div className="flex justify-content-center">
          <div className="card w-20rem md:w-auto">
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid mt-2">
              {loadingPaymentMethods ? (
                <Spinner size="small" />
              ) : (
                <div className="mb-5">
                  <div className="flex flex-column md:flex-row md:justify-content-between align-items-center md:text-center mb-2">
                    {/* Using <Controller> on a <RadioButton> component results in unexpected errors */}
                    <input
                      type="hidden"
                      {...register(PAYMENT_METHOD_ID_FAKE, {
                        required: "Debe seleccionar un medio de pago.",
                      })}
                    />

                    {/* Real <RadioButton> component */}
                    {paymentMethods.map((paymentMethod) => (
                      <div key={paymentMethod.id} className="w-10rem">
                        <RadioButton
                          inputId={paymentMethod.item}
                          name={PAYMENT_METHOD_ID_REAL} // Same name
                          value={paymentMethod}
                          onChange={handleChange}
                          checked={
                            selectedPaymentMethod
                              ? selectedPaymentMethod.item ===
                                paymentMethod.item
                              : false
                          }
                          className={`paymentMethodRadioButton mt-4 md:mt-0 mr-2 ${classNames(
                            {
                              "p-invalid": errors[PAYMENT_METHOD_ID_FAKE],
                            }
                          )}`}
                        />
                        <label
                          htmlFor={paymentMethod.item}
                          className={classNames({
                            "p-error": errors[PAYMENT_METHOD_ID_FAKE],
                          })}
                        >
                          {paymentMethod.label}
                        </label>
                      </div>
                    ))}
                  </div>
                  {getFormErrorMessage(PAYMENT_METHOD_ID_FAKE)}
                </div>
              )}

              {loadingBanks ? (
                <Spinner size="small" />
              ) : (
                <>
                  {!loadingPaymentMethods &&
                    selectedPaymentMethod?.item === paymentMethods[0].item && (
                      <div className="mb-5">
                        <span className="p-float-label">
                          <Controller
                            name={BANK_ID}
                            control={control}
                            rules={{
                              required: "Debe seleccionar un banco.",
                            }}
                            render={({ field, fieldState }) => (
                              <Dropdown
                                id={field.name}
                                value={field.value}
                                onChange={(e) => field.onChange(e.value)}
                                options={banks}
                                optionLabel="label"
                                className={classNames({
                                  "p-invalid": fieldState.invalid,
                                })}
                                inputRef={field.ref}
                              />
                            )}
                          />
                          <label
                            htmlFor={BANK_ID}
                            className={classNames({
                              "p-error": errors[BANK_ID],
                            })}
                          >
                            Banco
                            <span className="p-error font-bold ml-1">*</span>
                          </label>
                        </span>
                        <small className="ml-1 block text-500">
                          {`Para poder efectuar un pago en el sitio de testing de Sistarbanc debe elegir el banco Demo`}
                        </small>
                        {getFormErrorMessage(BANK_ID)}
                      </div>
                    )}
                </>
              )}

              {loadingCreditCards ? (
                <Spinner size="small" />
              ) : (
                <>
                  {!loadingPaymentMethods &&
                    selectedPaymentMethod?.item === paymentMethods[1].item && (
                      <div className="mb-5">
                        <span className="p-float-label">
                          <Controller
                            name={CREDIT_CARD_ID}
                            control={control}
                            rules={{
                              required:
                                "Debe seleccionar una tarjeta de crédito.",
                            }}
                            render={({ field, fieldState }) => (
                              <Dropdown
                                id={field.name}
                                value={field.value}
                                onChange={(e) => field.onChange(e.value)}
                                options={creditCards}
                                optionLabel="label"
                                className={classNames({
                                  "p-invalid": fieldState.invalid,
                                })}
                                inputRef={field.ref}
                              />
                            )}
                          />
                          <label
                            htmlFor={CREDIT_CARD_ID}
                            className={classNames({
                              "p-error": errors[CREDIT_CARD_ID],
                            })}
                          >
                            Tarjeta de crédito
                            <span className="p-error font-bold ml-1">*</span>
                          </label>
                        </span>
                        {getFormErrorMessage(CREDIT_CARD_ID)}
                      </div>
                    )}
                </>
              )}

              <BlockUI
                blocked={blockedDocument}
                fullScreen
                template={<Spinner size="big" />}
              />

              {loadingPaymentMethods && loadingBanks && loadingCreditCards ? (
                <Spinner size="small" />
              ) : (
                <Button
                  type="submit"
                  label="Continuar"
                  className="p-button-info mt-2"
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentMethod;
