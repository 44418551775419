import logoBSE from "../../images/bse-logo-negative.png";
import Header from "./Header";

function PaymentFlowHeader() {
  return (
    <Header
      bgColor="bg-indigo-900"
      logo={logoBSE}
      heightLogo="h-4rem"
      title="PAGO ELECTRÓNICO"
    />
  );
}

export default PaymentFlowHeader;
