import PaymentFlowInsurance from "../routes/PaymentFlowInsurance";
import PaymentFlowMethod from "../routes/PaymentFlowMethod";
import PaymentFlowPolicyDetail from "../routes/PaymentFlowPolicyDetail";
import {
  PAYMENT_FLOW_ROUTE_METHOD,
  PAYMENT_FLOW_ROUTE_POLICY_DETAIL,
  PAYMENT_FLOW_ROUTE_INSURANCE,
} from "../routes/routes";

const paymentFlowStepsConfig = [
  {
    label: "1",
    description: "Método de pago",
    route: PAYMENT_FLOW_ROUTE_METHOD,
    element: <PaymentFlowMethod />,
  },
  {
    label: "2",
    description: "Detalle de póliza",
    route: PAYMENT_FLOW_ROUTE_POLICY_DETAIL,
    element: <PaymentFlowPolicyDetail />,
  },
  {
    label: "3",
    description: "Seguro contratado",
    route: PAYMENT_FLOW_ROUTE_INSURANCE,
    element: <PaymentFlowInsurance />,
  },
];

const getPaymentFlowStepConfig = (step) => {
  return 0 < step && step <= paymentFlowStepsConfig.length
    ? paymentFlowStepsConfig[step - 1]
    : null;
};

const getAllPaymentFlowStepsConfig = () => {
  return paymentFlowStepsConfig;
};

export { getPaymentFlowStepConfig, getAllPaymentFlowStepsConfig };
