import { Button } from "primereact/button";
import { useSelector, useDispatch } from "react-redux";

import { MUST_QUOTE_PREFIX, QUOTATION_PREFIX } from "../../utils/constants";
import { getCoverageInfo } from "../../utils/coverageInfo";
import { bikeFlowStepCompletedThunk } from "../../reduxToolkit/bikeFlowSlice";
import Spinner from "./Spinner";
import recomended from "../../images/recomended.png";
import "./QuoteInfo.css";

function QuoteInfo({ coverageType, coveragePlanPerson }) {
  const mustQuote = useSelector(
    (state) => state.bikeFlow.quoteInfo[MUST_QUOTE_PREFIX + coverageType.item]
  );
  const insurance = useSelector(
    (state) => state.bikeFlow.quoteInfo[QUOTATION_PREFIX + coverageType.item]
  );
  const dispatch = useDispatch();

  const coverageItem = getCoverageInfo(coverageType.item);

  const handleClick = () => {
    dispatch(bikeFlowStepCompletedThunk({ coveragePlanPerson, insurance }));
  };

  return (
    <>
      {mustQuote ? (
        <Spinner size="medium" />
      ) : (
        <div id="content" className="col-12 md:col-6">
          <div className="md:px-3 py-3 h-full">
            <div className="shadow-3 p-3 flex flex-column border-round border-teal-500 border-2 bg-teal-50">
              {coverageItem.isPopular && (
                <>
                  <div
                    className="bg-green-500 text-0 font-bold text-sm inline-block p-2 mb-3 text-center m-auto w-7 md:w-6"
                    style={{ borderRadius: "20px" }}
                  >
                    POPULAR
                  </div>
                  <img
                    className="recomended w-4rem md:w-6rem"
                    src={recomended}
                    alt="recomendado"
                  />
                </>
              )}
              <div className="font-bold text-2xl mb-2 text-primary">
                {coverageType.label}
              </div>
              <div>{coverageItem.planDescription}</div>
              <hr className="my-3 mx-0 border-top-1 border-bottom-none border-400 border-primary" />
              <div className="flex align-items-center">
                <span className="font-bold text-2xl text-900 text-primary">
                  {`${insurance.currencySymbol} ${parseFloat(
                    insurance.premium
                  ).toLocaleString("es-ES", {
                    style: "decimal",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}`}
                </span>
                <span className="ml-2 text-sm text-600">total (imp. inc.)</span>
              </div>
              <hr className="my-3 mx-0 border-top-1 border-bottom-none border-400 border-primary" />
              <ul className="list-none p-0 m-0 flex-grow-1">
                {coverageItem.benefits.map((benefit) => {
                  return (
                    <li
                      key={benefit.id}
                      className="flex align-items-center mb-3"
                    >
                      <i
                        className={`pi pi-check-circle ${benefit.checkColor} mr-2`}
                      ></i>
                      {benefit.benefit}
                    </li>
                  );
                })}
              </ul>
              <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-400 border-primary" />
              <Button
                label="Contratar"
                className={`p-3 w-full ${coverageItem.buttonType}`}
                onClick={handleClick}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default QuoteInfo;
