import { useDispatch } from "react-redux";
import { useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Message } from "primereact/message";
import { Captcha } from "primereact/captcha";
import { OverlayPanel } from "primereact/overlaypanel";

import useDataCollection from "../../hooks/useDataCollection";
import useFocusController from "../../hooks/useFocusController";
import {
  MUST_QUOTE_PREFIX,
  QUOTATION_PREFIX,
  RECAPTCHA_SITE_KEY,
  WHATSAPP,
  CONTACT_FORM,
} from "../../utils/constants";
import {
  addQuoteInfo,
  bikeFlowStepCompletedThunk,
} from "../../reduxToolkit/bikeFlowSlice";
import {
  API_BIKE_COVERAGES,
  API_BIKE_INSURANCE_PERIODS,
  API_SITE_VERIFY,
} from "../../utils/apiUrls";
import styles from "./BasicData.module.css";
import Spinner from "./Spinner";
import { clientApi } from "../../utils/clientApi";

function BasicData() {
  // Constants
  const BIKE_COST_VALIDATION = {
    min: 500,
    max: 3000,
  };
  const WIDHT_THRESHOLD = 400;
  const BIKE_COST_ID = "bikeCost";
  const BIKE_INSURANCE_PERIOD_ID = "bikeInsurancePeriod";
  const CAPTCHA_ID = "captcha";

  const dispatch = useDispatch();
  const [loadingBikeInsurancePeriods, bikeInsurancePeriods] = useDataCollection(
    API_BIKE_INSURANCE_PERIODS
  );
  const [loadingCoverageTypes, coverageTypes] =
    useDataCollection(API_BIKE_COVERAGES);
  const captchaErrorRef = useRef(null);
  const overlayPanel = useRef(null);

  // Ids and focus functions of all elements on the screen in order.
  const elements = [
    {
      id: BIKE_COST_ID,
      focus: () => setFocus(BIKE_COST_ID),
    },
    {
      id: BIKE_INSURANCE_PERIOD_ID,
      focus: () => setFocus(BIKE_INSURANCE_PERIOD_ID),
    },
    {
      id: CAPTCHA_ID,
      focus: () => captchaErrorRef.current.click(),
    },
  ];

  const defaultValues = JSON.parse(
    `{"${BIKE_COST_ID}":${BIKE_COST_VALIDATION.min}, "${BIKE_INSURANCE_PERIOD_ID}":"", "${CAPTCHA_ID}":""}`
  );

  const {
    register,
    control,
    setValue,
    setError,
    formState: { errors },
    handleSubmit,
    reset,
    setFocus,
  } = useForm({ defaultValues });

  const errorKeys = Object.keys(errors);

  useFocusController(elements, errorKeys);

  const onSubmit = (data) => {
    coverageTypes.forEach((coverageType) => {
      const quoteInfo = {
        [MUST_QUOTE_PREFIX + coverageType.item]: true,
        [QUOTATION_PREFIX + coverageType.item]: {},
      };
      dispatch(addQuoteInfo(quoteInfo));
    });
    dispatch(bikeFlowStepCompletedThunk(data));
    reset();
  };

  // Verify against recaptcha with private key
  const siteVerify = async (recaptchaToken) => {
    const response = await clientApi("post", API_SITE_VERIFY, false, {
      recaptchaToken,
    });
    if (response.ok) {
      setValue(CAPTCHA_ID, recaptchaToken, {
        shouldValidate: true,
      });
    } else {
      setError(CAPTCHA_ID, {
        type: "manual",
        message:
          "La verificación ha fallado, por favor vuelva a cargar la página.",
      });
      setValue(CAPTCHA_ID, "", {
        shouldValidate: false,
      });
    }
  };

  const handleResponse = (response) => {
    const token = response.response;
    siteVerify(token);
  };

  const handleExpire = () => {
    setValue(CAPTCHA_ID, "", {
      shouldValidate: true,
    });
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <Message severity="error" text={errors[name].message} />
    );
  };

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  return (
    <>
      <div className="text-900 font-bold text-3xl mb-4 text-center text-primary mt-5">
        DESCUBRÍ UN SEGURO PENSADO PARA TI
      </div>
      <div className="flex flex-column text-700 text-xl mb-4 text-center line-height-3">
        <span>Contratar tu seguro es muy fácil.</span>
        <span>
          Ingresa los datos básicos, selecciona tu plan de cobertura y paga en
          línea!
        </span>
      </div>

      <div className="form-data">
        <div className="flex justify-content-center">
          <div className="card">
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid mt-2">
              <div className="mb-5">
                <span className="field">
                  <label
                    htmlFor={BIKE_COST_ID}
                    className={classNames({ "p-error": errors[BIKE_COST_ID] })}
                  >
                    <i
                      className="pi pi-bell cursor-pointer text-primary hover:text-orange-500 mr-2"
                      onClick={(e) => overlayPanel.current.toggle(e)}
                      aria-haspopup
                      aria-controls="overlayPanel"
                    ></i>
                    <span className="text-xs text-600">
                      Valor de la bici en dólares
                    </span>
                    <span className="p-error font-bold ml-1">*</span>
                  </label>
                  <Controller
                    name={BIKE_COST_ID}
                    control={control}
                    rules={{
                      required: "Debe ingresar el valor de la bici en dólares.",
                    }}
                    render={({ field, fieldState }) => (
                      <InputNumber
                        id={field.name}
                        autoFocus
                        value={field.value}
                        onValueChange={(e) => field.onChange(e.value)}
                        showButtons
                        decrementButtonClassName="p-button-secondary"
                        incrementButtonClassName="p-button-primary"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                        min={BIKE_COST_VALIDATION.min}
                        max={BIKE_COST_VALIDATION.max}
                        prefix="U$S "
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        inputRef={field.ref}
                      />
                    )}
                  />
                </span>
                {getFormErrorMessage(BIKE_COST_ID)}
                <OverlayPanel
                  ref={overlayPanel}
                  showCloseIcon
                  id="overlayPanel"
                  className="w-16rem md:w-30rem"
                >
                  <div className="text-sm">
                    Para valor de bici superior a U$S
                    <span className="mx-1">{BIKE_COST_VALIDATION.max}</span>
                    el seguro requiere cotización específica. Puede solicitarlo
                    a través del whatsapp
                    <a
                      href={WHATSAPP}
                      target="_blank"
                      rel="noreferrer"
                      className="font-medium no-underline mx-1 text-blue-500 hover:text-blue-300 cursor-pointer"
                    >
                      098611998
                    </a>
                    o nuestro
                    <a
                      href={CONTACT_FORM}
                      target="_blank"
                      rel="noreferrer"
                      className="font-medium no-underline mx-1 text-blue-500 hover:text-blue-300 cursor-pointer"
                    >
                      formulario de contacto.
                    </a>
                  </div>
                </OverlayPanel>
              </div>

              <div className="mb-5">
                {loadingBikeInsurancePeriods ? (
                  <Spinner size="small" />
                ) : (
                  <>
                    <span className="p-float-label">
                      <Controller
                        name={BIKE_INSURANCE_PERIOD_ID}
                        control={control}
                        rules={{
                          required:
                            "Debe seleccionar la duración de la cobertura.",
                        }}
                        render={({ field, fieldState }) => (
                          <Dropdown
                            id={field.name}
                            value={field.value}
                            onChange={(e) => field.onChange(e.value)}
                            options={bikeInsurancePeriods}
                            optionLabel="label"
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            inputRef={field.ref}
                          />
                        )}
                      />
                      <label
                        htmlFor={BIKE_INSURANCE_PERIOD_ID}
                        className={classNames({
                          "p-error": errors[BIKE_INSURANCE_PERIOD_ID],
                        })}
                      >
                        Duración de la cobertura
                        <span className="p-error font-bold ml-1">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(BIKE_INSURANCE_PERIOD_ID)}
                  </>
                )}
              </div>

              <a
                style={{ display: "none" }}
                href="#captchaError"
                ref={captchaErrorRef}
              >
                Link hidden
              </a>
              <div className="mb-5" id="captchaError">
                {/* Using <Controller> on a <Captcha> component results in unexpected errors */}
                <input
                  type="hidden"
                  {...register(CAPTCHA_ID, {
                    required: "Debe seleccionar la casilla de verificación.",
                  })}
                />
                {/* Real <Captcha> component */}
                <div
                  className={`text-center m-auto max-w-min ${
                    getWindowDimensions().width <= WIDHT_THRESHOLD
                      ? styles["recaptcha-compact"]
                      : styles["recaptcha-normal"]
                  } ${classNames({
                    "border-solid border-1 p-error": errors[CAPTCHA_ID],
                  })}`}
                >
                  <Captcha
                    language="es"
                    size={
                      getWindowDimensions().width <= WIDHT_THRESHOLD
                        ? "compact"
                        : "normal"
                    }
                    siteKey={RECAPTCHA_SITE_KEY}
                    onResponse={handleResponse}
                    onExpire={handleExpire}
                  />
                </div>
                {getFormErrorMessage(CAPTCHA_ID)}
              </div>

              {loadingBikeInsurancePeriods || loadingCoverageTypes ? (
                <Spinner size="small" />
              ) : (
                <Button type="submit" label="Cotizar" className="my-2" />
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicData;
