import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { Menu } from "primereact/menu";
import { Avatar } from "primereact/avatar";

import {
  selectAuthenticated,
  logoutThunk,
  selectUserProfiles,
  selectUserId,
} from "../../reduxToolkit/userSlice";

function UserAvatar() {
  const menu = useRef(null);
  const toast = useRef(null);

  const dispatch = useDispatch();
  const auth = useSelector(selectAuthenticated);
  const id = useSelector(selectUserId);
  const profiles = useSelector(selectUserProfiles);

  const items = [
    {
      label: "Opciones",
      items: [
        {
          label: "Salir",
          icon: "pi pi-sign-out",
          command: () => {
            handleLogout();
            toast.current.show({
              severity: "success",
              summary: "Salir",
              detail: "Salida exitosa",
              life: 3000,
            });
          },
        },
      ],
    },
  ];

  const handleLogout = () => {
    dispatch(logoutThunk());
  };

  return (
    <>
      <Toast ref={toast} />
      {auth && (
        <div className="flex flex-column md:flex-row">
          <Tooltip target=".userData" />
          <Menu model={items} popup ref={menu} id="popup_menu" />

          <div
            onClick={(event) => menu.current.toggle(event)}
            aria-controls="popup_menu"
            aria-haspopup
          >
            <Avatar
              icon="pi pi-user"
              className="bg-primary text-white cursor-pointer"
              size="large"
              shape="circle"
            />
          </div>

          <div
            className="userData mt-2 md:m-auto"
            data-pr-tooltip={`${id.documentType}: ${id.documentId} ${profiles.publicProfile.email}`}
            data-pr-position="bottom"
          >
            <span className="md:ml-2 font-semibold text-white text-lg uppercase">
              {profiles.publicProfile.fullName}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default UserAvatar;
