import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  bikeFlowGoToStep,
  selectBikeFlowStep,
  selectBikeFlowNavigate,
  bikeFlowNavigate,
  bikeFlowInit,
  bikeFlowGoToFirstStep,
} from "../reduxToolkit/bikeFlowSlice";
import {
  paymentFlowGoToStep,
  paymentFlowInit,
  paymentFlowNavigate,
  selectPaymentFlowNavigate,
  selectPaymentFlowStep,
} from "../reduxToolkit/paymentFlowSlice";
import { getAllBikeFlowStepsConfig } from "../utils/bikeFlowStepsConfig";
import { routeToStep } from "../utils/stepsHelper";
import { getAllPaymentFlowStepsConfig } from "../utils/paymentFlowStepsConfig";

function useAppFlowController() {
  const bikeFlowSteps = getAllBikeFlowStepsConfig();
  const paymentFlowSteps = getAllPaymentFlowStepsConfig();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [accessGranted, setAccessGranted] = useState(false);

  const bikeFlowStep = useSelector(selectBikeFlowStep);
  const bikeFlowNavigation = useSelector(selectBikeFlowNavigate);

  const paymentFlowStep = useSelector(selectPaymentFlowStep);
  const paymentFlowNavigation = useSelector(selectPaymentFlowNavigate);

  // The bikeFlowStep only changes if a dispatch was executed before.
  // If there is a pending navigation, we navigate to the indicated route.
  useEffect(() => {
    if (bikeFlowNavigation.navigate) {
      navigate(bikeFlowNavigation.navigateTo);
      dispatch(bikeFlowNavigate({ navigate: false }));
    }
  }, [
    bikeFlowStep,
    navigate,
    dispatch,
    bikeFlowNavigation.navigate,
    bikeFlowNavigation.navigateTo,
  ]);

  // The paymentFlowStep only changes if a dispatch was executed before.
  // If there is a pending navigation, we navigate to the indicated route.
  useEffect(() => {
    if (paymentFlowNavigation.navigate) {
      navigate(paymentFlowNavigation.navigateTo);
      dispatch(paymentFlowNavigate({ navigate: false }));
    }
  }, [
    paymentFlowStep,
    navigate,
    dispatch,
    paymentFlowNavigation.navigate,
    paymentFlowNavigation.navigateTo,
  ]);

  useEffect(() => {
    if (!bikeFlowNavigation.navigate && !paymentFlowNavigation.navigate) {
      // Steps that correspond to the current url
      const currentUrl = location.pathname;
      const urlBikeFlowStep = routeToStep(bikeFlowSteps, currentUrl);
      const urlPaymentFlowStep = routeToStep(paymentFlowSteps, currentUrl);

      if (urlBikeFlowStep > 0 || urlPaymentFlowStep > 0) {
        // The current url is inside the payment flow
        if (urlPaymentFlowStep > 0) {
          if (paymentFlowStep > urlPaymentFlowStep) {
            console.log("*** ACCESS GRANTED TO", currentUrl);
            dispatch(paymentFlowGoToStep(urlPaymentFlowStep));
            setAccessGranted(true);
          } else {
            if (paymentFlowStep < urlPaymentFlowStep) {
              console.log("*** ACCESS DENIED TO", currentUrl);
              dispatch(bikeFlowInit());
              dispatch(paymentFlowInit());
              dispatch(bikeFlowGoToFirstStep());
              setAccessGranted(false);
            } else {
              // We are already there
              console.log("*** ACCESS GRANTED TO", currentUrl);
              setAccessGranted(true);
            }
          }
        } else {
          // The current url is inside the bike flow
          dispatch(paymentFlowInit());
          if (bikeFlowStep > urlBikeFlowStep) {
            console.log("*** ACCESS GRANTED TO", currentUrl);
            dispatch(bikeFlowGoToStep(urlBikeFlowStep));
            setAccessGranted(true);
          } else {
            if (bikeFlowStep < urlBikeFlowStep) {
              console.log("*** ACCESS DENIED TO", currentUrl);
              dispatch(bikeFlowInit());
              dispatch(bikeFlowGoToFirstStep());
              setAccessGranted(false);
            } else {
              // We are already there
              console.log("*** ACCESS GRANTED TO", currentUrl);
              setAccessGranted(true);
            }
          }
        }
      } else {
        // Page not found
        setAccessGranted(false);
        console.log("*** ACCESS DENIED TO", currentUrl);
      }
    }
  }, [
    bikeFlowSteps,
    paymentFlowSteps,
    bikeFlowNavigation.navigate,
    bikeFlowStep,
    dispatch,
    location.pathname,
    paymentFlowNavigation.navigate,
    paymentFlowStep,
  ]);

  return accessGranted;
}

export default useAppFlowController;
