import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Message } from "primereact/message";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { InputText } from "primereact/inputtext";

import useDataCollection from "../../hooks/useDataCollection";
import useFocusController from "../../hooks/useFocusController";
import { bikeFlowStepCompletedThunk } from "../../reduxToolkit/bikeFlowSlice";
import Spinner from "./Spinner";
import { API_BIKE_TYPES } from "../../utils/apiUrls";

function AdditionalData() {
  // Constants
  const BIKE_TYPE_ID = "bikeType";
  const INVOICE_DATE_ID_FAKE = "invoiceDateFake";
  const INVOICE_DATE_ID_REAL = "invoiceDateReal";
  const BRAND_ID = "brand";
  const SERIAL_ID = "serial";

  // Calendar
  const handleChange = (event) => {
    setInvoiceDate(event.value);
    setValue(INVOICE_DATE_ID_FAKE, event.value, {
      shouldValidate: true,
    });
  };

  addLocale("es", {
    firstDayOfWeek: 1,
    dayNames: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
    dayNamesMin: ["DO", "LU", "MA", "MI", "JU", "VI", "SA"],
    monthNames: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    monthNamesShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    today: "Hoy",
    clear: "Claro",
  });

  let today = new Date();
  let month = today.getMonth();
  let year = today.getFullYear();

  let minDate = new Date();
  minDate.setMonth(month);
  minDate.setFullYear(year - 2);

  let maxDate = new Date();
  maxDate = today;

  function dateTemplate(date) {
    let result;
    if (date.selectable) {
      if (date.today) {
        result = (
          <div className="flex flex-column justify-content-center align-items-center bg-green-200 border-circle w-3rem h-3rem">
            <div className="bg-primary font-bold border-circle w-2rem h-2rem line-height-4 p-0 text-center">
              {date.day}
            </div>
          </div>
        );
      } else if (
        invoiceDate &&
        date.day === invoiceDate.getDate() &&
        date.month === invoiceDate.getMonth() &&
        date.year === invoiceDate.getFullYear()
      ) {
        result = (
          <div className="flex flex-column justify-content-center align-items-center bg-orange-200 border-circle w-3rem h-3rem">
            <div className="bg-orange-500 font-bold text-white border-circle w-2rem h-2rem line-height-4 p-0 text-center">
              {date.day}
            </div>
          </div>
        );
      } else {
        result = <div className="text-primary font-semibold">{date.day}</div>;
      }
    } else {
      result = <div className="text-400">{date.day}</div>;
    }

    return result;
  }

  const dispatch = useDispatch();
  const [loadingBikeTypes, bikeTypes] = useDataCollection(API_BIKE_TYPES);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const invoiceDateErrorRef = useRef(null);

  // Ids and focus functions of all elements on the screen in order.
  const elements = [
    {
      id: BIKE_TYPE_ID,
      focus: () => setFocus(BIKE_TYPE_ID),
    },
    {
      id: INVOICE_DATE_ID_FAKE,
      focus: () => invoiceDateErrorRef.current.click(),
    },
    {
      id: BRAND_ID,
      focus: () => setFocus(BRAND_ID),
    },
    {
      id: SERIAL_ID,
      focus: () => setFocus(SERIAL_ID),
    },
  ];

  const defaultValues = JSON.parse(
    `{"${BIKE_TYPE_ID}":"", "${INVOICE_DATE_ID_FAKE}":"", "${BRAND_ID}":"", "${SERIAL_ID}":""}`
  );

  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
    setFocus,
  } = useForm({ defaultValues });

  const errorKeys = Object.keys(errors);

  useFocusController(elements, errorKeys);

  const onSubmit = (data) => {
    const { invoiceDateFake, ...otherData } = data;
    dispatch(
      bikeFlowStepCompletedThunk({ invoiceDate: invoiceDateFake, ...otherData })
    );
    reset();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <Message severity="error" text={errors[name].message} />
    );
  };

  return (
    <>
      <div className="text-900 font-bold text-3xl mb-4 text-center text-primary">
        INFORMACIÓN ADICIONAL
      </div>
      <div className="text-700 text-xl mb-4 text-center line-height-3">
        Contanos algo más sobre tu bici
      </div>

      <div className="form-data">
        <div className="flex justify-content-center">
          <div className="card">
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid mt-5">
              <div className="mb-5">
                {loadingBikeTypes ? (
                  <Spinner size="small" />
                ) : (
                  <>
                    <span className="p-float-label">
                      <Controller
                        name={BIKE_TYPE_ID}
                        control={control}
                        rules={{
                          required: "Debe seleccionar el tipo de bicicleta.",
                        }}
                        render={({ field, fieldState }) => (
                          <Dropdown
                            id={field.name}
                            autoFocus
                            value={field.value}
                            onChange={(e) => field.onChange(e.value)}
                            options={bikeTypes}
                            optionLabel="label"
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            inputRef={field.ref}
                          />
                        )}
                      />
                      <label
                        htmlFor={BIKE_TYPE_ID}
                        className={classNames({
                          "p-error": errors[BIKE_TYPE_ID],
                        })}
                      >
                        Tipo de bici
                        <span className="p-error font-bold ml-1">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(BIKE_TYPE_ID)}
                  </>
                )}
              </div>

              <a
                style={{ display: "none" }}
                href="#invoiceDateError"
                ref={invoiceDateErrorRef}
              >
                Link hidden
              </a>
              <div className="mb-5" id="invoiceDateError">
                {/* Using <Controller> on a <Calendar> component results in unexpected errors */}
                <input
                  type="hidden"
                  {...register(INVOICE_DATE_ID_FAKE, {
                    required: "Debe seleccionar la fecha de factura.",
                  })}
                />

                {/* Real <Calendar> component */}
                <div
                  className={classNames({
                    "border-solid border-1 p-error":
                      errors[INVOICE_DATE_ID_FAKE],
                  })}
                >
                  <span className="p-float-label">
                    <Calendar
                      id={INVOICE_DATE_ID_REAL}
                      value={invoiceDate}
                      onChange={handleChange}
                      locale="es"
                      dateFormat="dd/mm/yy"
                      minDate={minDate}
                      maxDate={maxDate}
                      dateTemplate={dateTemplate}
                      readOnlyInput
                      showIcon
                    />
                    <label
                      htmlFor={INVOICE_DATE_ID_REAL}
                      className={classNames({
                        "p-error": errors[INVOICE_DATE_ID_FAKE],
                      })}
                    >
                      Fecha de factura
                      <span className="p-error font-bold ml-1">*</span>
                    </label>
                  </span>
                </div>

                {getFormErrorMessage(INVOICE_DATE_ID_FAKE)}
              </div>

              <div className="mb-5">
                <span className="p-float-label">
                  <Controller
                    name={BRAND_ID}
                    control={control}
                    rules={{
                      required: "Debe ingresar una marca.",
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    htmlFor={BRAND_ID}
                    className={classNames({ "p-error": errors[BRAND_ID] })}
                  >
                    Marca
                    <span className="p-error font-bold ml-1">*</span>
                  </label>
                </span>
                {getFormErrorMessage(BRAND_ID)}
              </div>

              <div className="mb-5">
                <span className="p-float-label">
                  <Controller
                    name={SERIAL_ID}
                    control={control}
                    rules={{
                      required: "Debe ingresar la serie.",
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    htmlFor={SERIAL_ID}
                    className={classNames({ "p-error": errors[SERIAL_ID] })}
                  >
                    Serie
                    <span className="p-error font-bold ml-1">*</span>
                  </label>
                </span>
                {getFormErrorMessage(SERIAL_ID)}
              </div>

              {loadingBikeTypes ? (
                <Spinner size="small" />
              ) : (
                <Button type="submit" label="Contratar" className="mt-2" />
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdditionalData;
