import { useDispatch, useSelector } from "react-redux";

import BikeFlowSteps from "./BikeFlowSteps";
import useAppFlowController from "../../hooks/useAppFlowController";
import Detail from "../bikeInsQuote/Detail";
import BikeFlowHeader from "./BikeFlowHeader";
import BikeFlowFooter from "./BikeFlowFooter";
import {
  bikeFlowGoToFirstStep,
  selectBikeFlowStep,
} from "../../reduxToolkit/bikeFlowSlice";
import { getFirstStep } from "../../utils/stepsHelper";

function BikeFlowLayout({ children }) {
  const accessGranted = useAppFlowController();

  const dispatch = useDispatch();
  const bikeFlowStep = useSelector(selectBikeFlowStep);

  const handleClick = () => {
    dispatch(bikeFlowGoToFirstStep());
  };

  return (
    <>
      {accessGranted && (
        <div className="bg-green-50">
          <BikeFlowHeader />
          <div className="mx-auto my-5 shadow-4 p-4 w-11 md:w-6 border-round bg-white">
            <BikeFlowSteps />
            <div className="mt-3">
              <Detail />
            </div>
            <div className="mt-3">{children}</div>
            {bikeFlowStep > getFirstStep() && (
              <div className="text-500 font-medium mt-3">
                <span
                  onClick={handleClick}
                  className="cursor-pointer hover:text-primary"
                >
                  <i className="pi pi-angle-double-left mr-2" />
                  Inicio
                </span>
              </div>
            )}
          </div>
          <BikeFlowFooter />
        </div>
      )}
    </>
  );
}

export default BikeFlowLayout;
