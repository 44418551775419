import Quote from "../components/bikeInsQuote/Quote";
import BikeFlowLayout from "../components/common/BikeFlowLayout";

function BikeFlowQuote() {
  return (
    <BikeFlowLayout>
      <Quote />
    </BikeFlowLayout>
  );
}

export default BikeFlowQuote;
