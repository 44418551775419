import { useLocation } from "react-router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  bikeFlowGoToFirstStep,
  bikeFlowInit,
} from "../reduxToolkit/bikeFlowSlice";
import useAppFlowController from "../hooks/useAppFlowController";
import { paymentFlowInit } from "../reduxToolkit/paymentFlowSlice";

function PageNotFound() {
  const dispatch = useDispatch();
  const location = useLocation();

  // Needed to navigate to home
  useAppFlowController();

  useEffect(() => {
    console.error("*** PAGE NOT FOUND", location.pathname);
    console.error("*** REDIRECTING TO FIRST STEP");
    dispatch(bikeFlowInit());
    dispatch(paymentFlowInit());
    dispatch(bikeFlowGoToFirstStep());
  }, [dispatch, location.pathname]);

  return <></>;
}

export default PageNotFound;
