import { useSelector } from "react-redux";
import {
  selectBikeFlowSelectedData,
  selectBikeFlowStep,
} from "../../reduxToolkit/bikeFlowSlice";

function Detail() {
  const selectedData = useSelector(selectBikeFlowSelectedData);
  const bikeFlowStep = useSelector(selectBikeFlowStep);

  return (
    <>
      {bikeFlowStep > 1 && (
        <div className="grid grid-nogutter border-top-1 border-bottom-1 surface-border pt-2 text-center">
          {bikeFlowStep >= 2 && selectedData.bikeCost && (
            <div
              className={`col-12 ${
                bikeFlowStep >= 3 ? "md:col-4" : "md:col-6"
              } p-3`}
            >
              <div className="text-500 font-medium mb-2">Valor de la Bici</div>
              <div className="text-900">
                {`U$S ${parseFloat(selectedData.bikeCost).toLocaleString(
                  "es-ES",
                  {
                    style: "decimal",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }
                )}`}
              </div>
            </div>
          )}

          {bikeFlowStep >= 2 && selectedData.bikeInsurancePeriod && (
            <div
              className={`col-12 ${
                bikeFlowStep >= 3 ? "md:col-4" : "md:col-6"
              } p-3`}
            >
              <div className="text-500 font-medium mb-2">
                Duración de la cobertura
              </div>
              <div className="text-900">
                {selectedData.bikeInsurancePeriod.label}
              </div>
            </div>
          )}

          {bikeFlowStep >= 3 && selectedData.insurance && (
            <div className="col-12 md:col-4 p-3">
              <div className="text-500 font-medium mb-2">Plan de cobertura</div>
              <div className="text-900">
                {selectedData.insurance.coverageLabel}
              </div>
            </div>
          )}

          {bikeFlowStep >= 4 && selectedData.paymentPlan && (
            <div className="col-12 md:col-4 p-3">
              <div className="text-500 font-medium mb-2">Plan de pagos</div>
              <div className="text-900">{selectedData.paymentPlan.label}</div>
            </div>
          )}

          {bikeFlowStep >= 4 && selectedData.paymentPlan && (
            <div className="col-12 md:col-4 p-3">
              <div className="text-500 font-medium mb-2">
                Costo total (imp. inc.)
              </div>
              <div className="text-900">
                {`U$S ${selectedData.paymentPlan.total.toLocaleString("es-ES", {
                  style: "decimal",
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}`}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Detail;
