import coverageBiciBse from "../images/coverage-bici-bse.png";
import coverageBiciBseVida from "../images/coverage-bici-bse-vida.png";

function getCoverageInfo(coverageItem) {
  const coverageInfo = [
    {
      id: 1,
      item: "BICI_BSE",
      planDescription: (
        <img
          src={coverageBiciBse}
          className="border-round w-full h-full"
          alt="BICI BSE"
        />
      ),
      buttonType: "p-button-outlined",
      isPopular: false,
      benefits: [
        {
          id: 1,
          checkColor: "text-orange-500",
          benefit: <span>Contratación inmediata</span>,
        },
        {
          id: 2,
          checkColor: "text-orange-500",
          benefit: <span>Denuncia de siniestros 24/7</span>,
        },
        {
          id: 3,
          checkColor: "text-orange-500",
          benefit: <span>Pérdida total por robo directo o en domicilio</span>,
        },
        {
          id: 4,
          checkColor: "text-orange-500",
          benefit: <span>Daño accidental</span>,
        },
        {
          id: 5,
          checkColor: "text-orange-500",
          benefit: <span>Reposición de tu bici según el valor contratado</span>,
        },
      ],
    },
    {
      id: 2,
      item: "BICI_BSE_VIDA",
      planDescription: (
        <img
          src={coverageBiciBseVida}
          className="border-round w-full h-full"
          alt="BICI BSE + VIDA"
        />
      ),
      buttonType: "",
      isPopular: true,
      benefits: [
        {
          id: 1,
          checkColor: "text-orange-500",
          benefit: <span>Contratación inmediata</span>,
        },
        {
          id: 2,
          checkColor: "text-orange-500",
          benefit: <span>Denuncia de siniestros 24/7</span>,
        },
        {
          id: 3,
          checkColor: "text-orange-500",
          benefit: <span>Pérdida total por robo directo o en domicilio</span>,
        },
        {
          id: 4,
          checkColor: "text-orange-500",
          benefit: <span>Daño accidental</span>,
        },
        {
          id: 5,
          checkColor: "text-orange-500",
          benefit: <span>Reposición de tu bici según el valor contratado</span>,
        },
        {
          id: 6,
          checkColor: "text-green-500",
          benefit: <span>Accidentes personales del ciclista</span>,
        },
        {
          id: 7,
          checkColor: "text-green-500",
          benefit: <span>Cobertura de daños a terceros</span>,
        },
      ],
    },
  ];

  return coverageInfo.find((element) => element.item === coverageItem);
}

export { getCoverageInfo };
