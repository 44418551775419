import logoBikeBSE from "../../images/bike-bse-logo.png";
import Header from "./Header";

function BikeFlowHeader() {
  return (
    <Header
      bgColor="bg-cyan-900"
      logo={logoBikeBSE}
      heightLogo="h-8rem"
      title="COTIZÁ EL SEGURO DE TU BICI"
    />
  );
}

export default BikeFlowHeader;
