import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { BlockUI } from "primereact/blockui";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { Divider } from "primereact/divider";

import {
  loginThunk,
  LOGIN_FAILED,
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  selectAuthenticatedState,
} from "../../reduxToolkit/userSlice";
import Spinner from "../bikeInsQuote/Spinner";
import { DUMMY_LINK } from "../../utils/constants";
import useFocusController from "../../hooks/useFocusController";
import useDataCollection from "../../hooks/useDataCollection";
import { API_DOCUMENT_TYPES, API_USERS } from "../../utils/apiUrls";
import { selectBikeFlowSelectedData } from "../../reduxToolkit/bikeFlowSlice";
import bseLogo from "../../images/bse-logo.png";

function Login() {
  // Constants
  const DOCUMENT_TYPE_ID = "documentType";
  const DOCUMENT_ID_ID = "documentId";
  const PASSWORD_ID = "password";
  const RUT_ITEM = "RUT";

  const authenticatedState = useSelector(selectAuthenticatedState);
  const selectedData = useSelector(selectBikeFlowSelectedData);
  const dispatch = useDispatch();

  const [loadingValidUsers, validUsers] = useDataCollection(API_USERS);
  const [blockedDocument, setBlockedDocument] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loadingDocumentTypes, documentTypes] =
    useDataCollection(API_DOCUMENT_TYPES);

  // Ids and focus functions of all elements on the screen in order.
  const elements = [
    {
      id: DOCUMENT_TYPE_ID,
      focus: () => setFocus(DOCUMENT_TYPE_ID),
    },
    {
      id: DOCUMENT_ID_ID,
      focus: () => setFocus(DOCUMENT_ID_ID),
    },
    {
      id: PASSWORD_ID,
      focus: () => setFocus(PASSWORD_ID),
    },
  ];

  const defaultValues = JSON.parse(
    `{"${DOCUMENT_TYPE_ID}":"", "${DOCUMENT_ID_ID}":"", "${PASSWORD_ID}":""}`
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    setFocus,
  } = useForm({ defaultValues });

  const errorKeys = Object.keys(errors);

  useFocusController(elements, errorKeys);

  const getDocumentTypes = () => {
    return selectedData.coveragePlanPerson
      ? documentTypes.filter((documentType) => documentType.item !== RUT_ITEM)
      : documentTypes.filter((documentType) => documentType.item === RUT_ITEM);
  };

  const onSubmit = (data) => {
    const { documentType, documentId, password } = data;

    dispatch(
      loginThunk({
        documentType: documentType.item,
        documentTypeLabel: documentType.label,
        documentId,
        password,
      })
    );
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <Message severity="error" text={errors[name].message} />
    );
  };

  useEffect(() => {
    switch (authenticatedState) {
      case LOGIN_STARTED:
        setBlockedDocument(true);
        setLoginError(false);
        break;
      case LOGIN_SUCCESS:
        setBlockedDocument(false);
        setLoginError(false);
        break;
      case LOGIN_FAILED:
        setBlockedDocument(false);
        setLoginError(true);
        break;
      default:
        setBlockedDocument(false);
    }
  }, [authenticatedState]);

  return (
    <div className="w-full h-full bg-primary">
      <div className="flex align-items-center justify-content-center w-10 md:w-4 mx-auto">
        <div className="surface-card p-4 shadow-4 border-round w-full my-8">
          <div className="text-center mb-2">
            <img src={bseLogo} alt="hyper" height={50} className="mb-3" />
            <div className="text-900 text-3xl font-medium mb-3">Bienvenido</div>
            <span className="text-600 font-medium line-height-3">
              No tiene una cuenta?
            </span>
            <a
              href={DUMMY_LINK}
              target="_blank"
              rel="noreferrer"
              className="font-medium no-underline ml-2 text-blue-500 hover:text-blue-300 cursor-pointer"
            >
              Regístrese ahora!
            </a>
          </div>

          <div className="flex justify-content-center">
            <div className="w-full">
              <form onSubmit={handleSubmit(onSubmit)} className="p-fluid mt-5">
                <div className="mb-5">
                  {loadingDocumentTypes ? (
                    <Spinner size="small" />
                  ) : (
                    <>
                      <span className="p-float-label">
                        <Controller
                          name={DOCUMENT_TYPE_ID}
                          control={control}
                          rules={{
                            required: "Debe seleccionar el tipo de documento.",
                          }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              id={field.name}
                              autoFocus
                              value={field.value}
                              onChange={(e) => field.onChange(e.value)}
                              options={getDocumentTypes()}
                              optionLabel="label"
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              inputRef={field.ref}
                            />
                          )}
                        />
                        <label
                          htmlFor={DOCUMENT_TYPE_ID}
                          className={classNames({
                            "p-error": errors[DOCUMENT_TYPE_ID],
                          })}
                        >
                          Tipo de documento
                          <span className="p-error font-bold ml-1">*</span>
                        </label>
                      </span>
                      {getFormErrorMessage(DOCUMENT_TYPE_ID)}
                    </>
                  )}
                </div>

                <div className="mb-5">
                  <span className="p-float-label">
                    <Controller
                      name={DOCUMENT_ID_ID}
                      control={control}
                      rules={{
                        required: "Debe ingresar el número de documento.",
                      }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />
                    <label
                      htmlFor={DOCUMENT_ID_ID}
                      className={classNames({
                        "p-error": errors[DOCUMENT_ID_ID],
                      })}
                    >
                      Número de documento
                      <span className="p-error font-bold ml-1">*</span>
                    </label>
                  </span>
                  {getFormErrorMessage(DOCUMENT_ID_ID)}
                </div>

                <div className="mb-5">
                  <span className="p-float-label">
                    <Controller
                      name={PASSWORD_ID}
                      control={control}
                      rules={{
                        required: "Debe ingresar una contraseña.",
                      }}
                      render={({ field, fieldState }) => (
                        <Password
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          inputRef={field.ref}
                          feedback={false}
                          toggleMask
                        />
                      )}
                    />
                    <label
                      htmlFor={PASSWORD_ID}
                      className={classNames({ "p-error": errors[PASSWORD_ID] })}
                    >
                      Contraseña
                      <span className="p-error font-bold ml-1">*</span>
                    </label>
                  </span>
                  {getFormErrorMessage(PASSWORD_ID)}
                </div>

                <div className="text-right mb-6">
                  <a
                    href={DUMMY_LINK}
                    target="_blank"
                    rel="noreferrer"
                    className="font-medium no-underline ml-2 text-blue-500 hover:text-blue-300 text-right cursor-pointer"
                  >
                    Olvidó su contraseña?
                  </a>
                </div>

                <BlockUI
                  blocked={blockedDocument}
                  fullScreen
                  template={<Spinner size="big" />}
                />
                {loginError && (
                  <Message
                    severity="error"
                    text="Documento y/o contraseña incorrecta."
                    className="w-full mb-2"
                  />
                )}

                {loadingDocumentTypes ? (
                  <Spinner size="small" />
                ) : (
                  <Button
                    type="submit"
                    label="Ingresar"
                    icon="pi pi-user"
                    className="w-full"
                  />
                )}
              </form>
              <Divider align="center" type="dashed" />
              <div className="flex flex-column align-items-center">
                <ul className="list-none m-0 p-0 text-500 text-xs">
                  {loadingValidUsers ? (
                    <Spinner size="small" />
                  ) : (
                    <>
                      {validUsers.map((validUser) => {
                        return (
                          <li key={validUser.id}>
                            <i className="pi pi-user mb-2 mr-3" />
                            <span className="mr-1">
                              {validUser.documentTypeLabel}
                            </span>
                            <span className="mr-1">{validUser.documentId}</span>
                            <span>{validUser.password}</span>
                          </li>
                        );
                      })}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
