import AdditionalData from "../components/bikeInsQuote/AdditionalData";
import BikeFlowLayout from "../components/common/BikeFlowLayout";

function BikeFlowAdditionalData() {
  return (
    <BikeFlowLayout>
      <AdditionalData />
    </BikeFlowLayout>
  );
}

export default BikeFlowAdditionalData;
