import { useDispatch, useSelector } from "react-redux";

import PaymentFlowSteps from "./PaymentFlowSteps";
import useAppFlowController from "../../hooks/useAppFlowController";
import PaymentFlowHeader from "./PaymentFlowHeader";
import PaymentFlowFooter from "./PaymentFlowFooter";
import { bikeFlowGoToFirstStep } from "../../reduxToolkit/bikeFlowSlice";
import { selectPaymentFlowStep } from "../../reduxToolkit/paymentFlowSlice";
import { getLastStep } from "../../utils/stepsHelper";
import { getAllPaymentFlowStepsConfig } from "../../utils/paymentFlowStepsConfig";

function PaymentFlowLayout({ children }) {
  const accessGranted = useAppFlowController();

  const dispatch = useDispatch();
  const paymentFlowStep = useSelector(selectPaymentFlowStep);

  const handleClick = () => {
    dispatch(bikeFlowGoToFirstStep());
  };

  return (
    <>
      {accessGranted && (
        <div className="bg-blue-50">
          <PaymentFlowHeader />
          <div className="mx-auto my-5 shadow-4 p-4 w-11 md:w-6 border-round bg-white">
            <PaymentFlowSteps />
            <div className="mt-3">{children}</div>
            {paymentFlowStep < getLastStep(getAllPaymentFlowStepsConfig()) && (
              <div className="text-500 font-medium mt-3">
                <span
                  onClick={handleClick}
                  className="cursor-pointer hover:text-blue-500"
                >
                  <i className="pi pi-angle-double-left mr-2" />
                  Inicio
                </span>
              </div>
            )}
          </div>
          <PaymentFlowFooter />
        </div>
      )}
    </>
  );
}

export default PaymentFlowLayout;
