import { createSlice } from "@reduxjs/toolkit";

import { API_PRIVATE_PROFILE, API_SESSIONS } from "../utils/apiUrls";
import { addToken, removeToken, validateToken } from "../utils/tokenHelper";
import { clientApi } from "../utils/clientApi";

const LOGIN_STARTED = "loginStarted";
const LOGIN_SUCCESS = "loginSuccess";
const LOGIN_FAILED = "loginFailed";

const initialState = {
  auth: false,
  authState: "",
  authError: "",
  id: {},
  profiles: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginStarted: (state) => {
      state.auth = false;
      state.authState = LOGIN_STARTED;
      state.authError = "";
    },
    loginSuccess: (state, action) => {
      const {
        token,
        documentType,
        documentTypeLabel,
        documentId,
        ...otherData
      } = action.payload;
      state.auth = true;
      state.authState = LOGIN_SUCCESS;
      state.id = { token, documentType, documentTypeLabel, documentId };
      state.profiles = { ...otherData };
    },
    loginFailed: (state, action) => {
      state.authState = LOGIN_FAILED;
      state.authError = action.payload;
      state.id = {};
      state.profiles = {};
    },
    logoutSuccess: (state) => {
      state.auth = false;
      state.authState = "";
      state.authError = "";
      state.id = {};
      state.profiles = {};
    },
  },
});

const { loginStarted, loginSuccess, loginFailed, logoutSuccess } =
  userSlice.actions;

const loginThunk = (loginData) => async (dispatch) => {
  const { documentType, documentTypeLabel, documentId, password } = loginData;

  // Start
  dispatch(loginStarted());

  const responseSessions = await clientApi(
    "post",
    API_SESSIONS,
    false,
    {},
    {
      documentType,
      documentTypeLabel,
      documentId,
      password,
    }
  );
  if (responseSessions.ok) {
    addToken(responseSessions.data.token);
    const responsePrivateProfile = await clientApi(
      "post",
      API_PRIVATE_PROFILE,
      true,
      {},
      {
        documentType,
        documentTypeLabel,
        documentId,
      }
    );
    if (responsePrivateProfile.ok) {
      // Login success
      dispatch(
        loginSuccess({
          ...responseSessions.data,
          ...responsePrivateProfile.data,
        })
      );
    } else {
      // Fail
      removeToken();
      dispatch(loginFailed(responsePrivateProfile.data));
    }
  } else {
    // Fail
    dispatch(loginFailed(responseSessions.data));
  }
};

const loginFromTokenThunk = () => async (dispatch) => {
  const {
    token,
    documentType,
    documentTypeLabel,
    documentId,
    publicProfile,
    isLoggedIn,
  } = validateToken();

  console.log("*** WORKING ON SSO...");
  if (!isLoggedIn) {
    console.log("*** LOGOUT REASON", "validateToken failure");
    dispatch(logoutThunk());
  } else {
    const responsePrivateProfile = await clientApi(
      "post",
      API_PRIVATE_PROFILE,
      true,
      {},
      {
        documentType,
        documentTypeLabel,
        documentId,
      }
    );
    if (responsePrivateProfile.ok) {
      console.log("*** SUCCESS loginFromTokenThunk");
      dispatch(
        loginSuccess({
          token,
          documentType,
          documentTypeLabel,
          documentId,
          publicProfile,
          ...responsePrivateProfile.data,
        })
      );
    } else {
      console.log("*** LOGOUT REASON", responsePrivateProfile.data);
      dispatch(logoutThunk());
    }
  }
};

const logoutThunk = () => (dispatch) => {
  removeToken();
  dispatch(logoutSuccess());
};

// Selectors
const selectAuthenticated = (state) => state.user.auth;
const selectAuthenticatedState = (state) => state.user.authState;
const selectAuthenticatedError = (state) => state.user.authError;
const selectUserId = (state) => state.user.id;
const selectUserProfiles = (state) => state.user.profiles;

export default userSlice.reducer;
export {
  selectAuthenticated,
  selectAuthenticatedState,
  selectAuthenticatedError,
  selectUserId,
  selectUserProfiles,
  loginThunk,
  loginFromTokenThunk,
  logoutThunk,
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
};
